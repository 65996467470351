body {
    background: '#f4f4f4';
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, html {
    /* background: #f4f3fc !important; */
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
}

.ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
}

/* Tree */

.level-0 {
    width: 50%;
    position: relative;
    margin: 0 auto 40px;
}
   
.level-0::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: #ddd;
}

.level-1 {
    width: 70%;
    position: relative;
    margin: 0 auto 40px;
}
   
.level-1::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: #ddd;
}
   
.level-1::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: #ddd;
  }

  
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.link {
    color: #4B00D1;
    text-decoration: none;
    cursor: pointer;
}  


.loaderPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.headerNav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.headerNav .link {
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
}

.spacer-x2 {
    margin-left: 20px;
    margin-right: 20px;
}

.section {
    position: relative;
}

.loginSection {
    background-image: url('../images/login-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: flex-end;
    height: 100vh;
}

.signupSection {
    background-image: url('../images/signup-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: flex-end;
    height: 100vh;
}

.forgotSection {
    background-image: url('../images/reset-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: flex-end;
    height: 100vh;
}


.resetSection {
    background-image: url('../images/reset-password.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: flex-end;
    height: 100vh;
}

.authWrapper {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.authTitle {
    color:  #5B2b9D;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 45px;
}

.authContent {
    display: flex;
    flex-direction: column;
}

.authForm {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.verticalField .authField:first-child {
    margin-right: 10px;
}

.verticalField .authField:last-child {
    margin-left: 10px;
}

.authField {
    margin-bottom: 15px !important;
}

.forgotPassword {
    text-align: right;
    margin-top: 5px;
    margin-bottom: 5px;
}


.linkAuth {
    margin-top: 20px;
    margin-bottom: 10px;
}


.hardwareSection {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-left: 75px;
    padding-right: 75px;
    margin-bottom: -230px;
}

.hardwareSection .slick-slide {
    width: 260px;
}

.slick-slide div {
    outline: none
}

.hardwareSection .slick-slide .sliderContent {
    padding: 3%;
    margin: 10px;
}

.hardwareSection .slick-dots {
    bottom: -35px;
}

.hardwareSection .center .sliderContent {
    transition: all .3s ease;
}

.hardwareSection .center .slick-center .sliderContent {  
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.featureSection .slick-slide .sliderContent {
    margin: 10px;
}



.priceSection {
    margin-top: -15px;
    margin-left: 75px;
    margin-right: 75px;
    margin-bottom: 125px;
    padding-top: 15px;
}

.priceSection .backgroundContent {
    background-image: url('../images/harga.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    min-height: 565px;
    width: 100%;
    margin-top: -55px;
}

.featureSection {
    margin-top: -15px;
    margin-left: 75px;
    margin-right: 75px;
    margin-bottom: 140px;
    padding-top: 15px;
}

.featureSection .backgroundContent {
    background-image: url('../images/fitur.svg');
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 615px;
    width: 100%;
    top: -110px;
    right: -30px;
    position: absolute;
}



.footerSection {
    background-image: url('../images/footer-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.footerSection .sectionContent {
    padding-top: 450px;
    margin-left: 75px;
    margin-right: 75px;
}

/* .menuWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 85px;
    z-index: 1301;
    height: calc(100vh - 0px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menuWrapper:before {
    background-color: #2c3e50;
    bottom: 0;
    content: "";
    left: 0;
    position: fixed;
    top: 0;
    width: 85px;
    z-index: 1000;
} */

.pageTitle {
    margin: 25px 0px 15px 0 !important;
    color: #212141;
}

/* .imageEmpty {
    border: 1px dashed #999;
    height: 125px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3;
    position: relative;
}

.imageEmpty.hasError {
    border-color: #f44336;   
}

.imageEmpty input[type="file"] {
    bottom: 0;
    cursor: pointer;
    font-size: 10em;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3;
} */
/* 
 .imagePreview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.imagePreview img {
    width: 100%
} */

.itemStatus {
	font-weight: bold !important;
}

.itemStatus.inStock {
	color: #155724
}

.itemStatus.outStock {
	color: #721c24
}

.itemStatus.itemOrdered {
	color: #004085
}

.itemStatus.lowStock {
	color: #856404
}


.MuiPopover-root {
    z-index: 1500 !important;
}

#chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
    
